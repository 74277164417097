export const columns = [
  {
    title: 'Line',
    dataIndex: 'line',
    key: 'line',
  },
  {
    title: 'Updated',
    dataIndex: 'updated',
    key: 'updated',
  },
  {
    title: 'No. of Operations',
    dataIndex: 'operationsCount',
    key: 'operationsCount',
  },
  {
    title: 'Operations',
    dataIndex: 'operations',
    key: 'operations',
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'action',
  },
];

export const stationsColumns = [
  {
    title: 'Operations',
    dataIndex: 'operations',
    key: 'operations',
  },
  {
    title: 'Travel Time',
    dataIndex: 'travelTime',
    key: 'travelTime',
  },
];

export const operationsColumns = [
  {
    title: 'Operations',
    dataIndex: 'operations',
    key: 'operations',
    width: 20,
  },
  {
    title: 'Meta Key',
    dataIndex: 'identificationKey',
    key: 'identificationKey',
    width: 30,
  },
  {
    title: 'Travel Time',
    dataIndex: 'travelTime',
    key: 'travelTime',
    width: 30,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: 20,
  },
];

export const metaDataColumns = [
  {
    title: 'Meta Data',
    dataIndex: 'metaDataKey',
    key: 'metaDataKey',
  },
  {
    title: 'Value',
    dataIndex: 'metaDataValue',
    key: 'metaDataValue',
  },
];
