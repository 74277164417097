<template>
  <line-trace-modal
    :show-line-trace="showLineTrace"
    :is-edit="isEditModal"
    :line-trace-record="editLineRecord"
    :selected-operations-id="selectedOperationsId"
    @set-show-line-trace="setShowLineTrace"
    @submit-record="addOrUpdateRecordInList"
  />
  <a-modal
    id="operations-list-modal"
    v-model:visible="displayOperationModal"
    title="Operations List"
    :footer="null"
    centered
    destroy-on-close
    style="width: 40%"
    @cancel="displayOperationModal = false"
  >
    <a-table
      :columns="stationsColumns"
      :data-source="operationsList"
      :pagination="false"
      :scroll="{ x: 'max-content', y: 300 }"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'operations'">
          {{ record.operations }}
        </template>
        <template v-if="column.key === 'travelTime'">
          {{ record.travelTime ? record.travelTime : 'N/A' }}
        </template>
      </template>
    </a-table>
  </a-modal>

  <div class="p-4">
    <a-row :gutter="[0, 16]">
      <a-col span="24">
        <a-input
          v-model:value="searchLineTrace"
          placeholder="Search Line"
          class="w-25"
        >
          <template #suffix>
            <search-outlined style="color: rgba(0, 0, 0, 0.45)" />
          </template>
        </a-input>
        <a-button
          type="primary"
          class="float-right"
          :disabled="!allTasks?.length"
          @click="setShowLineTrace(true)"
        >
          Add Line
        </a-button>
      </a-col>
      <a-col span="24">
        <a-table
          :loading="isLoading"
          :columns="columns"
          :data-source="lineTraceDataList"
          :pagination="{ position: ['bottomCenter'] }"
          :scroll="{ x: 'max-content' }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'line'">
              {{ record.name }}
            </template>

            <template v-if="column.key === 'updated'">
              {{ dateHelper.formatDate(record.last_updated) ?? 'N/A' }}
            </template>

            <template v-if="column.key === 'operationsCount'">
              {{ getOperationsList(record).length }}
            </template>

            <template v-if="column.key === 'operations'">
              <a-space>
                <a-tag
                  v-for="(operation, ind) in getOperationsList(record).slice(
                    0,
                    3
                  )"
                  :key="ind"
                  color="blue"
                >
                  {{ operation }}
                </a-tag>
                <a-tooltip title="Show Operations">
                  <a-button
                    v-if="getOperationsList(record).length"
                    @click="showOperations(record)"
                  >
                    <template #icon>
                      <more-outlined />
                    </template>
                  </a-button>
                </a-tooltip>
              </a-space>
            </template>

            <template v-if="column.key === 'action'">
              <a-space>
                <a-button
                  class="text-white"
                  type="primary"
                  @click="redirectLineVisibility(record)"
                >
                  Visibility
                </a-button>
                <a-button
                  id="edit-line-btn"
                  class="text-white"
                  @click="editLineTraceRecord(record, index)"
                >
                  Edit
                </a-button>
                <a-button type="primary" danger @click="showDeletePopup = true">
                  <a-popconfirm
                    v-modal:visible="showDeletePopup"
                    title="Are you sure delete line trace?"
                    ok-text="Okay"
                    cancel-text="Cancel"
                    @confirm="deleteLineTrace(record, index)"
                    @cancel="showDeletePopup = false"
                  >
                    Delete
                  </a-popconfirm>
                </a-button>
              </a-space>
            </template>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { MoreOutlined, SearchOutlined } from '@ant-design/icons-vue';
import dateHelper from 'src/components/shared/Helpers/dateHelper.js';
import TelemetryService from 'src/services/telemetry';
import { mapActions, mapGetters } from 'vuex';
import { columns, stationsColumns } from './config.js';
import LineTraceModal from './LineTraceModal.vue';

export default {
  components: {
    SearchOutlined,
    MoreOutlined,
    LineTraceModal,
  },

  inject: ['toast'],

  setup() {
    return {
      columns,
      stationsColumns,
      dateHelper,
    };
  },

  data() {
    return {
      searchLineTrace: '',
      lineTraceList: [],
      operationsList: [],
      isLoading: false,
      showLineTrace: false,
      editLineRecord: {},
      isEditModal: false,
      displayOperationModal: false,
      showDeletePopup: false,
    };
  },

  computed: {
    ...mapGetters(['allTasks']),

    selectedOperationsId() {
      if (!this.lineTraceList.length) {
        return new Set();
      }

      return new Set(
        this.lineTraceList.flatMap((lineTrace) =>
          lineTrace.operations.map((operation) => operation.task.id)
        )
      );
    },

    lineTraceDataList() {
      if (!this.lineTraceList.length) {
        return [];
      }
      const searchLowerCase = this.searchLineTrace.toLowerCase().trim();
      return this.lineTraceList.filter((line) =>
        line.name.toLowerCase().includes(searchLowerCase)
      );
    },
  },

  created() {
    if (this.allTasks.length) return;
    this.getAllTasks();
  },

  mounted() {
    this.getLineTraceDetails();
  },

  methods: {
    ...mapActions(['getAllTasks', 'changeRoute']),

    async getLineTraceDetails() {
      this.isLoading = true;
      const [error, data] = await TelemetryService.getLineTrace();
      this.isLoading = false;
      if (error) {
        return this.toast.error('Failed to retrieve line trace.');
      }
      this.lineTraceList = data;
    },

    getOperationsList(record) {
      if (!record?.operations) return [];
      return record.operations.map((operation) => operation.task.taskName);
    },

    getOperationsListAndTravelTime(record) {
      if (!record?.operations) return [];
      return record.operations.map((operation) => {
        return {
          operations: operation.task.taskName,
          travelTime: operation?.travel_time
            ? dateHelper.formatDate(
                dateHelper.getTimeFromSeconds(operation.travel_time),
                'HH:mm:ss'
              )
            : null,
        };
      });
    },

    showOperations(record) {
      this.operationsList = this.getOperationsListAndTravelTime(record);
      this.displayOperationModal = true;
    },

    setShowLineTrace(visible) {
      this.showLineTrace = visible;
      if (!visible) {
        this.editLineRecord = {};
        this.isEditModal = false;
      }
    },

    addOrUpdateRecordInList(record) {
      if (!Object.prototype.hasOwnProperty.call(record, 'listIndex')) {
        this.lineTraceList.push(record);
        return;
      }
      this.lineTraceList[record.listIndex] = record;
    },

    editLineTraceRecord(record, index) {
      this.editLineRecord = record;
      this.editLineRecord.listIndex = index;
      this.isEditModal = true;
      this.setShowLineTrace(true);
    },

    async deleteLineTrace(record, index) {
      const removedItem = this.lineTraceList.splice(index, 1)[0];
      const [error] = await TelemetryService.removeLineTrace(record.id);
      if (error) {
        this.toast.error('Failed to delete line trace.');
        this.lineTraceList.splice(index, 0, removedItem);
        return;
      }
      this.toast.success('Line trace deleted successfully.');
    },

    redirectLineVisibility(record) {
      if (!record.operations.length)
        return this.toast.info('No operations exist.');
      const lastOperationId =
        record.operations[record.operations.length - 1]?.task?.id;
      if (!lastOperationId)
        return this.toast.error('Failed to get last operation.');

      this.changeRoute(['Line Visibility']);
      this.$router.push({
        path: '/user/line-visibility',
        query: {
          taskId: lastOperationId,
        },
      });
    },
  },
};
</script>
<style>
#edit-line-btn {
  background: #fbe460;
  border-color: #fbe460;
}

#edit-line-btn:hover {
  background: #ffc107;
  border-color: #ffc107;
}
</style>
