<template>
  <a-modal
    id="line-trace-modal"
    centered
    destroy-on-close
    style="width: 40%"
    ok-text="Save"
    :visible="showLineTrace"
    :title="isEdit ? 'Edit Line' : 'Add Line'"
    :mask-closable="false"
    :confirm-loading="isLoading"
    @ok="updateLineTrace"
    @cancel="handleAddLineTraceCancel"
  >
    <a-form
      ref="formRef"
      layout="horizontal"
      :model="formData"
      :rules="validationRules()"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      label-align="left"
    >
      <a-card title="Line Configuration" size="small" hoverable>
        <a-form-item label="Name" name="name">
          <a-input v-model:value="formData.name" :disabled="isLoading" />
        </a-form-item>
      </a-card>
      <a-card
        title="Operation Configuration"
        size="small"
        hoverable
        class="mt-2"
      >
        <a-form
          ref="metaFormRef"
          layout="horizontal"
          :model="formData"
          :rules="validationRulesMeta()"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          label-align="left"
        >
          <a-form-item label="Operation" name="operation">
            <a-select
              v-model:value="formData.operation"
              show-search
              :filter-option="filterOption"
              :options="intermediateOperationOptions"
              :disabled="shouldDisableOperationInput"
              @change="handleOperationChange"
            />
          </a-form-item>
          <a-form-item label="Meta Key" name="meta_key">
            <a-select
              v-model:value="formData.identificationKey"
              show-search
              :allow-clear="true"
              :not-found-content="null"
              :filter-option="filterOptionCaseSensitive"
              :options="operationMetaKeysOptions"
              :loading="isFetchingMetaData"
              :disabled="shouldDisableOperationInput"
              @search="handleSearchChange"
            />
          </a-form-item>
          <a-form-item label="Travel Time" name="travelTime">
            <a-time-picker
              v-model:value="formData.travelTime"
              placeholder="Select travel time"
              class="w-100"
              :disabled="shouldDisableOperationInput"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ offset: 4, span: 20 }">
            <a-button
              type="primary"
              :disabled="shouldDisableOperationInput"
              @click="handleAddOperation"
            >
              Add
            </a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-form>
    <a-table
      class="mt-3"
      :columns="operationsColumns"
      :data-source="operationList"
      :pagination="{ pageSize: 4, position: ['bottomCenter'] }"
      :scroll="{ x: 'max-content' }"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'operations'">
          {{ record.taskName }}
        </template>
        <template v-if="column.key === 'identificationKey'">
          <a-select
            v-if="editRecordData[record.id]?.isEditMode"
            v-model:value="record.identificationKey"
            show-search
            style="width: 100%; margin: -5px 0"
            :allow-clear="true"
            :not-found-content="null"
            :filter-option="filterOptionCaseSensitive"
            :options="editRecordData[record.id].metaKeysOptions"
            :loading="editRecordData[record.id].isFetchingMetaData"
            :disabled="shouldDisableOperationInput"
            @search="(value) => handleEditSearchChange(value, record)"
          />
          <template v-else>
            <a-tag :color="record.identificationKey ? 'green' : 'default'">
              {{ record?.identificationKey ? record.identificationKey : 'N/A' }}
            </a-tag>
          </template>
        </template>
        <template v-if="column.key === 'travelTime'">
          <a-time-picker
            v-if="editRecordData[record.id]?.isEditMode"
            v-model:value="record.travelTime"
            style="margin: -5px 0"
          />
          <template v-else>
            {{
              record?.travelTime
                ? dateHelper.formatDate(record.travelTime, 'HH:mm:ss')
                : 'N/A'
            }}
          </template>
        </template>
        <template v-if="column.key === 'action'">
          <template v-if="isEdit">
            <a-tooltip :title="displayEdit(record) ? 'Close' : 'Edit'">
              <a-button
                type="primary"
                class="ml-2"
                @click="handleEditClick(record)"
              >
                <template #icon>
                  <close-outlined
                    v-if="editRecordData[record.id]?.isEditMode"
                  />
                  <form-outlined v-else />
                </template>
              </a-button>
            </a-tooltip>
          </template>
          <a-popconfirm
            title="Are you sure remove this operation?"
            ok-text="Remove"
            cancel-text="Cancel"
            @confirm="deleteOperation(record)"
          >
            <a-button type="danger" class="ml-2">
              <template #icon>
                <delete-outlined />
              </template>
            </a-button>
          </a-popconfirm>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>

<script>
import {
  CloseOutlined,
  DeleteOutlined,
  FormOutlined,
} from '@ant-design/icons-vue';
import dateHelper from 'src/components/shared/Helpers/dateHelper.js';
import spaceMixin from 'src/mixins/handleSpace';
import TelemetryService from 'src/services/telemetry';
import { getSortedTask } from 'src/utils/task';
import { mapGetters } from 'vuex';
import { operationsColumns } from './config';

export default {
  components: {
    CloseOutlined,
    DeleteOutlined,
    FormOutlined,
  },
  mixins: [spaceMixin],
  inject: ['toast'],
  props: {
    showLineTrace: { type: Boolean, required: true, default: false },
    isEdit: { type: Boolean, required: false, default: false },
    lineTraceRecord: { type: Object, required: false, default: () => ({}) },
    selectedOperationsId: { type: Set, required: true, default: new Set() },
  },

  emits: ['setShowLineTrace', 'submitRecord'],

  setup() {
    return {
      operationsColumns,
      dateHelper,
    };
  },

  data() {
    return {
      formData: {
        listIndex: null,
        name: '',
        identificationKey: '',
        operation: null,
        travelTime: null,
      },
      operationList: [],
      isLoading: false,
      operationMetaKeysOptions: [],
      isFetchingMetaData: false,
      editRecordData: {},
      searchValue: null,
      editSearchValue: {},
    };
  },

  computed: {
    ...mapGetters(['allTasks', 'organization']),

    shouldDisableOperationInput() {
      if (!this.allTasks.length || this.isLoading) return true;
      return false;
    },

    intermediateOperationOptions() {
      const operations = getSortedTask(this.allTasks);
      const operationSet = new Set(
        (this.operationList || []).map((operation) => operation.id)
      );
      const operationsOptions = operations.filter(
        (obj) =>
          !operationSet.has(obj.value) &&
          !this.selectedOperationsId.has(obj.value)
      );

      return operationsOptions;
    },

    identificationKeyOptions() {
      if (!this.operationMetaKeys.length) return [];
      const temp = this.operationList.filter(
        (operation) => operation.id === this.formData.operation
      );
      const option = this.operationMetaKeys.filter((metaKey) =>
        temp.some((value) => value.identificationKey != metaKey)
      );
      return option.map((item) => {
        return { label: item, value: item };
      });
    },
  },

  watch: {
    showLineTrace() {
      if (!this.isEdit) return;
      this.setEditRecordData();
    },
  },

  methods: {
    validationRules() {
      return {
        name: [{ required: true, message: 'Name is required' }],
      };
    },

    validationRulesMeta() {
      return {
        operation: [{ required: true, message: 'Operation is required' }],
        meta_key: [{ required: true, message: 'Meta Key is required' }],
      };
    },

    displayEdit(record) {
      return (
        Object.prototype.hasOwnProperty.call(this.editRecordData, record.id) &&
        this.editRecordData[record.id].isEditMode
      );
    },

    async handleEditClick(record) {
      if (!Object.prototype.hasOwnProperty.call(this.editRecordData, record.id))
        this.editRecordData[record.id] = { isEditMode: true };
      else
        this.editRecordData[record.id].isEditMode =
          !this.editRecordData[record.id].isEditMode;

      if (!this.editRecordData[record.id].isEditMode) {
        this.editRecordData[record.id].isFetchingMetaData = false;
        return;
      }
      this.editRecordData[record.id].isFetchingMetaData = true;
      const [error, data] = await TelemetryService.fetchMetaData({
        organization: this.organization,
        task: record.id,
      });
      this.editRecordData[record.id].isFetchingMetaData = false;
      if (error) this.editRecordData[record.id].metaKeysOptions = [];
      this.editRecordData[record.id].metaKeysOptions = data
        .map((item) => {
          return item['key'];
        })
        .map((item) => {
          return { label: item, value: item };
        });
    },

    async handleOperationChange(operationId) {
      this.operationMetaKeysOptions = [];
      this.isFetchingMetaData = true;
      const [error, data] = await TelemetryService.fetchMetaData({
        organization: this.organization,
        task: operationId,
      });
      this.isFetchingMetaData = false;
      if (error) {
        return this.toast.error('Failed to retrieve meta keys.');
      }
      this.operationMetaKeysOptions = data
        .map((item) => {
          return item['key'];
        })
        .map((item) => {
          return { label: item, value: item };
        });
    },

    setEditRecordData() {
      this.formData.id = this.lineTraceRecord.id;
      this.formData.name = this.lineTraceRecord.name;
      this.formData.listIndex = this.lineTraceRecord.listIndex;
      this.operationList = this.lineTraceRecord.operations.map((item) => {
        return {
          id: item.task.id,
          taskName: item.task.taskName,
          identificationKey: item?.identification_key || '',
          travelTime: item?.travel_time
            ? this.dateHelper.getTimeFromSeconds(item.travel_time)
            : null,
        };
      });
    },

    getTotalSeconds(travelTime) {
      if (!travelTime) return 0;
      return (
        travelTime.hour() * 3600 +
        travelTime.minute() * 60 +
        travelTime.second()
      );
    },

    handleAddOperation() {
      if (!this.formData.operation)
        return this.toast.info('Please select an operation.');
      if (!this.formData.identificationKey)
        return this.toast.info('Please provide meta key.');
      const task = this.allTasks.find(
        (task) => task.id === this.formData.operation
      );
      this.operationList.push({
        id: this.formData.operation,
        taskName: task.taskName,
        identificationKey: this.formData.identificationKey,
        travelTime: this.formData.travelTime,
      });
      this.resetForm();
    },

    resetForm() {
      this.formData.operation = null;
      this.formData.identificationKey = null;
      this.formData.travelTime = null;
    },

    handleAddLineTraceCancel() {
      this.formData = {
        listIndex: null,
        name: '',
        identificationKey: '',
        operation: null,
        travelTime: null,
      };
      this.operationList = [];
      this.isLoading = false;
      this.editRecordData = {};
      this.operationMetaKeysOptions = [];
      this.isFetchingMetaData = false;
      this.$emit('setShowLineTrace', false);
    },

    async updateLineTrace() {
      if (!(await this.isFormValidate())) return;
      if (!this.operationList.length)
        return this.toast.info('Please add an operation and provide meta key.');
      this.isLoading = true;
      const payload = {
        name: this.formData.name,
        operations: this.operationList.map((item) => {
          return {
            task_id: item.id,
            identification_key: item.identificationKey,
            travel_time: this.getTotalSeconds(item.travelTime),
          };
        }),
      };
      const [error, data] = this.isEdit
        ? await TelemetryService.updateLineTrace(this.formData.id, payload)
        : await TelemetryService.addLineTrace(payload);
      this.isLoading = false;
      if (error) {
        const errorMessage =
          error?.response?.data?.error || 'Failed to add new line trace.';
        this.toast.error(errorMessage);
        return;
      } else {
        if (this.isEdit) data.listIndex = this.formData.listIndex;
        this.$emit('submitRecord', data);
      }
      this.handleAddLineTraceCancel();
    },

    deleteOperation(record) {
      this.operationList = this.operationList.filter(
        (operation) => operation.id !== record.id
      );
    },

    async isFormValidate() {
      try {
        await this.$refs?.formRef?.validate();
        return true;
      } catch (error) {
        this.toast.error('Please provide required fields.');
        return false;
      }
    },

    async isMetaValidate() {
      try {
        await this.$refs?.metaFormRef?.validate();
        return true;
      } catch (error) {
        this.toast.error('Please provide required fields.');
        return false;
      }
    },

    handleSearchChange(value) {
      if (!value) return;
      this.searchValue = value;
      this.formData.identificationKey = this.searchValue;
    },

    handleEditSearchChange(value, record) {
      if (!value) return;
      this.editSearchValue[record.id] = value;
      record.identificationKey = this.editSearchValue[record.id];
    },

    filterOptionCaseSensitive(input, option) {
      return option.label?.indexOf(input) >= 0;
    },
  },
};
</script>
